import {
  emptySegmentReserveProperties,
  identifyUser,
  sendEventToFacebook,
  trackEvent,
} from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import type {
  SignupRequestBody,
  SignupRequestResponseBody,
} from '@pages/api/v1/notifications/subscribe'

import { RequestMethod } from '@lib/api/consts'
import internalJsonFetch from '@lib/api/internalJsonFetch'
import ApiRoutes from '@lib/routes/ApiRoutes'
import type { TrackingSources } from '@lib/tracking/TrackingSources'

export default async function subscribeUserToTrialNotifications({
  email,
  nctId,
  source,
}: {
  email: string
  nctId: string
  source: TrackingSources
}) {
  const requestBody: SignupRequestBody = {
    email,
    nctId,
    source,
  }
  const result = await internalJsonFetch<SignupRequestResponseBody>({
    authenticated: false,
    body: requestBody,
    requestMethod: RequestMethod.PUT,
    url: ApiRoutes.v1.notifications.subscribe,
  })
  if (result.success && result.data) {
    identifyUser({ traits: { email }, userId: result.data.userIdToken })
    trackEvent(
      TrackingEvents.GET_NOTIFIED_ABOUT_TRIALS_SUCCESS,
      {
        email, // email is required to be hashed but segment will hash it for us
        path: ApiRoutes.v1.notifications.subscribe,
        source,
      },
      emptySegmentReserveProperties,
      sendEventToFacebook,
    )
  }
}
