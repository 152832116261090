import isResponsiveOrMonetized from '@modules/trials/helpers/isResponsiveOrMonetized'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { TrialSiteLocationInDatabaseForAlgolia } from '@modules/trials/types/TrialSiteLocationInDatabaseForAlgolia'
import {
  getHasSiteContactWithEmail,
  getHasVerifiedSiteContact,
} from './transformSiteLocationForAlgolia'

export default function getCustomRankScoreFromSiteLocation(
  trial: TrialInDatabaseForAlgolia,
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  const rankScoreContactHasEmail = getRankScoreContactHasEmail(trialLocation)
  const rankScoreContactHasReplied =
    getRankScoreContactHasReplied(trialLocation)
  const rankScoreContactIsVerified =
    getRankScoreContactIsVerified(trialLocation)
  const rankScoreRecruiting = getRankScoreForRecruitingStatus(trialLocation)
  const rankScoreResponsive = getRankScoreResponsive(trial, trialLocation)
  const rankScoreSponsorVerifiedTrial = getRankScoreSponsorVerifiedTrial(trial)
  const rankScoreSponsoredTrialLocation = getRankScoreSponsoredTrialLocation(
    trial,
    trialLocation,
  )

  return {
    rankScoreContactHasEmail,
    rankScoreContactHasReplied,
    rankScoreContactIsVerified,
    rankScoreRecruiting,
    rankScoreResponsive,
    rankScoreSponsorVerifiedTrial,
    rankScoreSponsoredTrialLocation,
  }
}

// "TrialLocation" distinct "status"
//   Completed
//   Not yet recruiting
//   Terminated
//   [NULL]
//   Enrolling by invitation
//   Suspended
//   Available
//   Withdrawn
//   Recruiting
//   Active, not recruiting
// Score is if the trial location is recruiting? 1 else: 0
function getRankScoreForRecruitingStatus(
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  return trialLocation.status === 'Recruiting' ? 1 : 0
}

// If at least one contact has replied, score this 1, otherwise 0
function getRankScoreContactHasReplied(
  _trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  return 0 // FIXME: We removed relay threads from the trial location join because it was blowing up prisma
  // Assertion violation on the database: `too many bind variables in prepared statement, expected maximum of 32767, received 32768`
  // return getHasSiteContactWithPastReply(trialLocation) ? 1 : 0
}

// If at least one contact has been verified, score this 1, otherwise 0
function getRankScoreContactIsVerified(
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  return getHasVerifiedSiteContact(trialLocation) ? 1 : 0
}

// If at least one contact has an email address, score this 1, otherwise 0
function getRankScoreContactHasEmail(
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  return getHasSiteContactWithEmail(trialLocation) ? 1 : 0
}

/**
 * isResponsiveOrMonetized checks if the site has responded by email or phone in < 48h, or if the trial location is monetized.
 * If the trial is monetized, we have a contract with them and we expect them to be responsive.
 * if unknown, return 3; if true then 9, otherwise return 1
 */
function getRankScoreResponsive(
  trial: TrialInDatabaseForAlgolia,
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  const trialIsResponsive = isResponsiveOrMonetized(trial, trialLocation)
  if (typeof trialIsResponsive === 'undefined') {
    return 3
  } else if (trialIsResponsive) {
    return 9
  }

  return 1
}

/** Trials that are sponsor verified, rank 10 */
function getRankScoreSponsorVerifiedTrial(trial: TrialInDatabaseForAlgolia) {
  if (trial.hasSponsorVerifiedData) {
    return 10
  } else return 0
}

/** Trials that are sponsor verified or trial location monetized, rank 10 */
function getRankScoreSponsoredTrialLocation(
  trial: TrialInDatabaseForAlgolia,
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  if (
    trial.hasSponsorVerifiedData ||
    Boolean(trialLocation.monetization?.updatedAt)
  ) {
    return 10
  } else return 0
}
