/* eslint-disable sort-keys */
import { isModuleCondition } from '@modules/conditions/helpers/isAuthoritativeCondition'
import { trialHasMonetizedSites } from '@modules/facilities/helpers/isTrialSiteMonetized'
import { convertLeafWithLabel } from '@modules/trials/helpers/algolia/convertLabelledUmlsTreeNumberToCategoryLevels'
import {
  getComorbiditiesExclusionFromTrial,
  getComorbiditiesInclusionFromTrial,
} from '@modules/trials/helpers/extractEntitiesFromEligibilityCriteria/facetsForAlgolia/getComorbiditiesFromTrial'
import {
  getConditionsExclusionFromTrial,
  getConditionsInclusionFromTrial,
} from '@modules/trials/helpers/extractEntitiesFromEligibilityCriteria/facetsForAlgolia/getConditionsFromTrial'
import {
  getDiseaseStageExclusionFromTrial,
  getDiseaseStageInclusionFromTrial,
} from '@modules/trials/helpers/extractEntitiesFromEligibilityCriteria/facetsForAlgolia/getDiseaseStageFromTrial'
import {
  getDiseaseTNMExclusionFromTrial,
  getDiseaseTNMInclusionFromTrial,
} from '@modules/trials/helpers/extractEntitiesFromEligibilityCriteria/facetsForAlgolia/getDiseaseTNMFromTrial'
import {
  getGeneticMarkersExclusionFromTrial,
  getGeneticMarkersInclusionFromTrial,
} from '@modules/trials/helpers/extractEntitiesFromEligibilityCriteria/facetsForAlgolia/getGeneticMarkersFromTrial'
import {
  getGradeGleasonScoreExclusionFromTrial,
  getGradeGleasonScoreInclusionFromTrial,
} from '@modules/trials/helpers/extractEntitiesFromEligibilityCriteria/facetsForAlgolia/getGradeGleasonScoreFromTrial'
import {
  getLineOfTherapyExclusionFromTrial,
  getLineOfTherapyInclusionFromTrial,
} from '@modules/trials/helpers/extractEntitiesFromEligibilityCriteria/facetsForAlgolia/getLineOfTherapyFromTrial'
import {
  getPriorTreatmentsExclusionFromTrial,
  getPriorTreatmentsInclusionFromTrial,
} from '@modules/trials/helpers/extractEntitiesFromEligibilityCriteria/facetsForAlgolia/getPriorTreatmentsFromTrial'
import getEcogScoreFromTrial from '@modules/trials/helpers/getEcogScoreFromTrial'
import getEffectivenessScoreFromTrial from '@modules/trials/helpers/getEffectivenessScoreFromTrial'
import getInterventionTypesFromTrial from '@modules/trials/helpers/getInterventionTypesFromTrial'
import getInterventionsHaveBackgroundTherapyAllowedFromTrial from '@modules/trials/helpers/getInterventionsHaveBackgroundTherapyAllowedFromTrial'
import getInterventionsHaveNoPlaceboFromTrial from '@modules/trials/helpers/getInterventionsHaveNoPlaceboFromTrial'
import { getLatestDavinciBriefSummary } from '@modules/trials/helpers/getLatestDavinciBriefSummary'
import { getPINamesFromTrial } from '@modules/trials/helpers/getPINamesFromTrial'
import getPatientQuotesFromTrial from '@modules/trials/helpers/getPatientQuotesFromTrial'
import { getPrimaryConditionFromTrial } from '@modules/trials/helpers/getPrimaryConditionFromTrial'
import getPrimaryTreatmentNameFromTrial from '@modules/trials/helpers/getPrimaryTreatmentNameFromTrial'
import getSafetyScoreForTrial, {
  hasOneFdaApprovedIntervention,
} from '@modules/trials/helpers/getSafetyScoreForTrial'
import { getSponsorNamesFromTrial } from '@modules/trials/helpers/getSponsorNamesFromTrial'
import { getTrialLocationLatLngs } from '@modules/trials/helpers/getTrialLocationLatLngs'
import { hasAvailableCountry } from '@modules/trials/helpers/hasAvailableCountry'
import hasEnrollmentClosingSoonFromTrial from '@modules/trials/helpers/hasEnrollmentClosingSoonFromTrial'
import hasImmunotherapyInterventionFromTrial from '@modules/trials/helpers/hasImmunotherapyInterventionFromTrial'
import hasNoCostForTreatment from '@modules/trials/helpers/hasNoCostForTreatment'
import hasUpdateInLastTwoYears from '@modules/trials/helpers/hasUpdateInLastTwoYears'
import isNewThisMonthFromTrial from '@modules/trials/helpers/isNewThisMonthFromTrial'
import isPaidTrial from '@modules/trials/helpers/isPaidTrial'
import isResponsive from '@modules/trials/helpers/isResponsive'
import isVeteranInclusionCriteriaFromTrial from '@modules/trials/helpers/isVeteranInclusionCriteriaFromTrial'
import getReimbursementTagsFromTrial from '@modules/trials/helpers/parseKeywordsFromTrial/getReimbursementTagsFromTrial'
import hasMonthlyVisitsRequiredFromTrial from '@modules/trials/helpers/parseKeywordsFromTrial/hasMonthlyVisitsRequiredFromTrial'
import hasOnlineKeywordsFromTrial from '@modules/trials/helpers/parseKeywordsFromTrial/hasOnlineKeywordsFromTrial'
import parseLocationNamesFromLocations from '@modules/trials/helpers/parseLocationNamesFromLocations'
import parseProtocolId from '@modules/trials/helpers/parseProtocolId'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import omit from 'lodash/omit'
import { buildConditionTree } from './buildConditionTree'
import getCustomRankScore from './getCustomRankScore'
import getPhaseList from './getPhaseList'
import {
  getSearchTermsFromConditionsList,
  getSearchTermsFromConditionsWithSubtypesList,
} from './getSearchTermsFromCondition'
import getSearchableConditions from './getSearchableConditions'

type TransformForAlgoliaOptions = {
  tags?: string[]
}

export type TransformTrialForAlgoliaProps = {
  conditionAncestors?: string[]
  geneticMarkers?: string[]
  searchCondition?: string
  tagOptions?: TransformForAlgoliaOptions
  trial: TrialInDatabaseForAlgolia
}

/**
 * We transform the trial as it is represented in the database into an object to put into an algolia search index.
 * There are a few constraints to consider:
 *  1- The index is publicly available so any crawler could pull down our whole index
 *  2- Algolia doesn't like it if the objects are too big (over 100k). This is very easy to hit seeing as we have some trials with 1k+ trial locations
 * @param trial - trial from the database
 * @returns an object that we insert into our publicly-available algolia search repository
 */
export default function transformForAlgolia({
  conditionAncestors = [],
  geneticMarkers = [],
  searchCondition,
  tagOptions = { tags: [] },
  trial,
}: TransformTrialForAlgoliaProps) {
  const phaseList = getPhaseList(trial.phaseList)

  // Condition synonyms are handled per Algolia index, see conditions:pushToAlgolia
  const conditionsFromCtGov = trial.conditions.filter(isModuleCondition)

  const conditions = getSearchTermsFromConditionsList(conditionsFromCtGov)
  const conditionTree = buildConditionTree(conditionsFromCtGov)
  const conditionsWithSubtypes =
    getSearchTermsFromConditionsWithSubtypesList(conditionsFromCtGov)

  // We may want to fill this with the common names for treatments and drug codes
  const interventions = Array.from(
    new Set<string>(
      trial.interventions.map((intervention) => intervention.name),
    ),
  )
  const interventionTypes = getInterventionTypesFromTrial(trial.interventions)
  const interventionHasImmunotherapy = hasImmunotherapyInterventionFromTrial(
    trial.interventions,
  )

  const keywords = Array.from(
    new Set<string>(
      trial.keywords.map((trialKeyword) => trialKeyword.keyword.keyword),
    ),
  )

  const browseConditions = Array.from(
    new Set<string>(
      trial.trialBrowseConditions.map(
        (trialBrowseCondition) => trialBrowseCondition.conditionName,
      ),
    ),
  )

  const browseInterventions = Array.from(
    new Set<string>(
      trial.trialBrowseInterventions.map(
        (trialBrowseIntervention) => trialBrowseIntervention.interventionName,
      ),
    ),
  )

  const parsedEligibilityCriteria = trial.parsedEligibilityCriteria
    .filter((crit) => crit.deletedAt === null)
    .map((crit) => {
      return {
        criterion: crit.criterion,
        isInclusion: crit.isInclusion,
        isPreScreenerEnabled: crit.isPreScreenerEnabled,
        summary: crit.assertiveSummary,
        tags: crit.tags,
      }
    })

  const locationNames = parseLocationNamesFromLocations(trial.trialLocations)

  // From the list of zips in locationNames, retrieve a list of { lat, lng }
  const _geoloc = getTrialLocationLatLngs(trial)
  const primaryTreatment = getPrimaryTreatmentNameFromTrial(trial)
  const primaryCondition = getPrimaryConditionFromTrial(trial)
  const reimbursementTags = getReimbursementTagsFromTrial(trial)
  const customRankScores = getCustomRankScore(trial)

  const latestDavinciBriefSummary = getLatestDavinciBriefSummary(trial)

  // Construct parent terms two ways: as a flat array of "parents" ancestors and using a hierarchy
  const conditionParentTerms =
    trial.conditions.find(
      (ctc) => ctc.isPrimaryCondition && ctc.parentTerms.length > 0,
    )?.parentTerms ?? []
  const conditionParentTermsTree =
    conditionParentTerms.length < 1
      ? {}
      : [...conditionParentTerms, primaryCondition].reduce(
          convertLeafWithLabel('conditionParentTermsTree'),
          {},
        )

  // Gather patient quote, could mash several together to get to a min char length
  const patientQuote = getPatientQuotesFromTrial(trial)?.[0]

  // Include full condition names to improve search accuracy when colloquial names are insufficient (e.g., 'Hypothalamic Obesity' vs. 'Obesity')
  const conditionFullNames = trial.conditions.map((trialCondition) => {
    return trialCondition.condition.name
  })

  return {
    // fields pulled from the database
    abbreviatedTitle: trial.abbreviatedTitle,
    ageList: trial.ageList,
    awardBadges: trial.awardBadges,
    biologicalSex: trial.biologicalSex,
    briefSummary: trial.briefSummary,
    briefTitle: trial.briefTitle,
    conjuredTitle: trial.conjuredTitle,
    comorbiditiesExclusion: getComorbiditiesExclusionFromTrial(trial),
    comorbiditiesInclusion: getComorbiditiesInclusionFromTrial(trial),
    conditionFullNames,
    conditionParentTerms,
    conditionParentTermsTree,
    conditionsExclusion: getConditionsExclusionFromTrial(trial),
    conditionsInclusion: getConditionsInclusionFromTrial(trial),
    conditionsWithSubtypes,
    ecogScore: getEcogScoreFromTrial(trial),
    effectivenessScore: getEffectivenessScoreFromTrial(trial),
    enrollmentCount: trial.enrollmentCount,
    davinciBriefSummaryDate: trial.davinciBriefSummaryDate,
    diseaseStageExclusion: getDiseaseStageExclusionFromTrial(trial),
    diseaseStageInclusion: getDiseaseStageInclusionFromTrial(trial),
    diseaseTNMExclusion: getDiseaseTNMExclusionFromTrial(trial),
    diseaseTNMInclusion: getDiseaseTNMInclusionFromTrial(trial),
    drugStatus: trial.drugStatus,
    geneticMarkers: getGeneticMarkersInclusionFromTrial(trial), // to do: should we remove this in search at some point in favor of geneticMarkersInclusion
    geneticMarkersExclusion: getGeneticMarkersExclusionFromTrial(trial),
    geneticMarkersInclusion: getGeneticMarkersInclusionFromTrial(trial),
    gradeGleasonScoreExclusion: getGradeGleasonScoreExclusionFromTrial(trial),
    gradeGleasonScoreInclusion: getGradeGleasonScoreInclusionFromTrial(trial),
    hasAccommodationReimbursement: reimbursementTags.includes(
      'Accommodation Reimbursement',
    ),
    hasBackgroundTherapyAllowed:
      getInterventionsHaveBackgroundTherapyAllowedFromTrial(trial),
    hasFdaApprovedDrug: hasOneFdaApprovedIntervention(trial.interventions),
    hasEnrollmentClosingSoon: hasEnrollmentClosingSoonFromTrial(trial),
    hasMonthlyVisits: hasMonthlyVisitsRequiredFromTrial(trial),
    hasNoCostForTreatment: hasNoCostForTreatment(trial),
    hasNoPlacebo: getInterventionsHaveNoPlaceboFromTrial(trial.interventions),
    hasOtherReimbursement: reimbursementTags.includes('Other Reimbursement'),
    hasTravelReimbursement: reimbursementTags.includes('Travel Reimbursement'),
    hasUpdateInLastTwoYears: hasUpdateInLastTwoYears(trial),
    healthyVolunteers: trial.healthyVolunteers,
    isForVeterans: isVeteranInclusionCriteriaFromTrial(trial),
    isOnline: hasOnlineKeywordsFromTrial(trial),
    isPaidTrial: isPaidTrial(trial),
    // This is now overwritten in transformSiteLocationForAlgolia, but ripping this out causes lots of type errors. Come back to this
    // TODO: https://linear.app/withpower/issue/POW-3263/clean-up-isresponsive-from-transformforalgolia-function
    isResponsive: isResponsive(trial),
    lastUpdatedDate: trial.lastUpdatedDate,
    latestDavinciBriefSummary,
    linesOfTherapyInclusion: getLineOfTherapyInclusionFromTrial(trial),
    linesOfTherapyExclusion: getLineOfTherapyExclusionFromTrial(trial),
    maximumAge: trial.maximumAge,
    minimumAge: trial.minimumAge,
    nctId: trial.nctId,
    newThisMonth: isNewThisMonthFromTrial(trial),
    nlpBriefSummary: trial.nlpBriefSummary,
    objectID: trial.nctId,
    orgStudyId: parseProtocolId(trial.orgStudyIdInfo),
    overallOfficialNames: trial.overallOfficials.map(
      (official) => official.name,
    ),
    overallStatus: trial.overallStatus,
    parsedEligibilityCriteria,
    patientQuote,
    phaseList,
    piNames: getPINamesFromTrial(trial),
    primaryCompletionDate: trial.primaryCompletionDate,
    priorTreatmentsExclusion: getPriorTreatmentsExclusionFromTrial(trial),
    priorTreatmentsInclusion: getPriorTreatmentsInclusionFromTrial(trial),
    responsibleParty: trial.responsibleParty,
    responsiblePartyInvestigatorFullName:
      trial.responsiblePartyInvestigatorFullName,
    responsiblePartyInvestigatorTitle: trial.responsiblePartyInvestigatorTitle,
    responsiblePartyInvestigatorAffiliation:
      trial.responsiblePartyInvestigatorAffiliation,
    slug: trial.slug,
    sponsorNames: getSponsorNamesFromTrial(trial),
    stageOfDisease: trial.stageOfDisease,
    stageOfTreatment: trial.stageOfTreatment,
    safetyScore: getSafetyScoreForTrial(trial),
    studyCompletionDate: trial.studyCompletionDate,
    studyStartDate: trial.studyStartDate,
    studyType: trial.studyType,
    targetDuration: trial.targetDuration,
    treatments: trial.treatments,
    // fields with transformations
    trialLocations: (
      trial.trialLocations
        .filter((location) => hasAvailableCountry(location.country))
        // only take 100 trials so that we don't blow our algolia max record size budget
        // be defensive about slice returning undefined
        .slice(0, 100) ?? []
    ).map((location) => omit(location, 'contacts')), // old index doesn't need contacts and creates bloat
    primaryCondition,
    primaryTreatment,
    // Search conditions is the "options" of the <Select> used by the "Condition Autocomplete" search
    searchConditions: getSearchableConditions({
      additionalExclusions: geneticMarkers,
      conditionAncestors,
      conditions,
      searchCondition,
    }),
    conditionAncestors,
    conditions,
    interventions,
    interventionTypes,
    interventionHasImmunotherapy,
    isVerified: trialHasMonetizedSites(trial.trialLocations),
    keywords,
    browseConditions,
    browseInterventions,
    ...locationNames,
    _geoloc,
    _tags: tagOptions.tags,
    ...conditionTree,
    ...customRankScores,
  }
}
