import { coerceToNumber } from '@lib/utilities/number/coerceToNumber'
import { TWO_DAYS_IN_SECONDS } from '@lib/utilities/time'
import { HELPFUL_INFORMATION_FOR_PATIENTS_CATEGORIES } from '../etl/ingestTrial2024/handleTrialHelpfulInformation/consts'
import type { TrialHelpfulInformationForPatientEntries } from '../types/TrialHelpfulInformationForPatientEntries'
import type { TrialInDatabaseForAlgolia } from '../types/TrialInDatabaseForAlgolia'

/**
 * Return undefined if unknown, false if response time is > 48h or true if < 48h.
 * If a trial has a managing trial pro, it is assumed to be responsive.
 *
 * @see handleRelayBasedAverageResponseTime.ts for this tightly coupled data structure
 *
 * @param trial Trial to determine responsiveness
 */
export default function isResponsive(trial: TrialInDatabaseForAlgolia) {
  const hasManagingTrialPro =
    trial.managingTrialPros.filter((mtp) => mtp.validatedAt).length > 0

  if (hasManagingTrialPro) {
    return true
  }

  const responsiveCategory =
    HELPFUL_INFORMATION_FOR_PATIENTS_CATEGORIES['how-responsive-is-this-trial']
  const responseTimeRecord = trial.helpfulInformationForPatients.find(
    (info) =>
      info.category === responsiveCategory &&
      info.title === 'Average response time',
  )

  if (!responseTimeRecord) {
    return
  }

  const averageResponseTimeEntry = (
    responseTimeRecord.entries as TrialHelpfulInformationForPatientEntries
  )[0]
  if (!averageResponseTimeEntry || !averageResponseTimeEntry.value) {
    return
  }

  const averageResponseTimeEntryInSeconds = coerceToNumber(
    averageResponseTimeEntry.value,
  )
  return averageResponseTimeEntryInSeconds < TWO_DAYS_IN_SECONDS
}
