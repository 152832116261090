import type { Prisma } from '@prisma/client'
import { object, string } from 'zod'

// Generally there is an OrgStudyId key ingested from clinicaltrials.gov,
// but for some trials manually created an id key is used instead.
const ProtocolIdTypeValidator = object({
  OrgStudyId: string(),
  id: string(),
})
  .partial()
  .transform((data) => data.OrgStudyId ?? data.id)

export default function parseProtocolId(
  orgStudyIdInfo: Prisma.JsonValue | null,
) {
  const validatedOrgStudyInfo =
    ProtocolIdTypeValidator.safeParse(orgStudyIdInfo)

  return validatedOrgStudyInfo.success ? validatedOrgStudyInfo.data : undefined
}
