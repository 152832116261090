import {
  getAllSearchParams,
  searchParamFromUrl,
  type SearchParam,
} from '@components/hooks/ParamsProvider/searchParams'
import {
  DEFAULT_FILTERS,
  useSearchUrlParameters,
} from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import useIsPartnerPage from '@components/hooks/useIsPartnerPage'
import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { faRefresh } from '@fortawesome/pro-regular-svg-icons/faRefresh'
import { faWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons/faWandMagicSparkles'
import { faX } from '@fortawesome/pro-regular-svg-icons/faX'
import { trackEvent } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import pickBy from 'lodash/pickBy'
import { useClearRefinements, useSearchBox } from 'react-instantsearch'
import { ApplySearchHistoryPill } from './ApplySearchHistoryPill'

export const SearchPills = () => {
  const query = searchParamFromUrl('query')
  const filterPlacebo = searchParamFromUrl('hasNoPlacebo')
  const geneticMarkers = searchParamFromUrl('geneticMarkers')
  const diseaseStageInclusion = searchParamFromUrl('diseaseStageInclusion')
  const diseaseTNM = searchParamFromUrl('diseaseTNMInclusion')
  const linesOfTherapyInclusion = searchParamFromUrl('linesOfTherapyInclusion')
  const conditions = searchParamFromUrl('conditions')
  const condition = searchParamFromUrl('condition')
  const grade = searchParamFromUrl('gradeGleasonScoreInclusion')
  const trialPhase = searchParamFromUrl('phaseList')
  const interventions = searchParamFromUrl('interventionTypes')

  const priorTreatmentsInclusion = searchParamFromUrl(
    'priorTreatmentsInclusion',
  )
  const { refine: setFreeTextSearch } = useSearchBox()

  const { updateUrlToReflectSearchState } = useSearchUrlParameters()

  const QueryPill = () => {
    if (!query) {
      return <></>
    }
    return (
      <div
        className='inline-block cursor-pointer rounded-lg bg-white p-1 px-3'
        onClick={() => {
          updateUrlToReflectSearchState({ query: undefined })
          setFreeTextSearch('')
        }}
      >
        <Text className='text-neutral600' styleName='p-small'>
          <Icon
            className='mr-3 text-primary600'
            icon={faWandMagicSparkles}
            size='sm'
          />
          {query}
          <Icon className='ml-2' icon={faX} size='xs' />
        </Text>
      </div>
    )
  }

  const PlaceboPill = () => {
    if (filterPlacebo === 'false') {
      return <></>
    }
    return (
      <div
        className='inline-block cursor-pointer rounded-lg bg-white p-1 px-3'
        onClick={() => {
          updateUrlToReflectSearchState({ hasNoPlacebo: 'false' })
        }}
      >
        <Text className='text-neutral600' styleName='p-small'>
          Trials Without a Placebo
          <Icon className='ml-2' icon={faX} size='xs' />
        </Text>
      </div>
    )
  }

  return (
    <div className='flex flex-wrap gap-2'>
      <ApplySearchHistoryPill />
      <QueryPill />
      <PlaceboPill />
      <FilterPill
        attribute='condition'
        attributeDisplay='Condition'
        extraClearAttribute='conditions'
        filterValues={condition}
      />
      <FilterPill
        attribute='conditions'
        attributeDisplay='Subtype'
        filterValues={conditions}
      />
      <FilterPill
        attribute='geneticMarkers'
        attributeDisplay='Genetic Marker'
        filterValues={geneticMarkers}
      />
      <FilterPill
        attribute='gradeGleasonScoreInclusion'
        attributeDisplay='Grade/Gleason Score'
        filterValues={grade}
      />
      <FilterPill
        attribute='diseaseStageInclusion'
        attributeDisplay='Disease Stage'
        filterValues={diseaseStageInclusion}
      />
      <FilterPill
        attribute='diseaseStageInclusion'
        attributeDisplay='Disease TNM'
        filterValues={diseaseTNM}
      />
      <FilterPill
        attribute='linesOfTherapyInclusion'
        attributeDisplay='Prior Treatment'
        filterValues={linesOfTherapyInclusion}
      />
      <FilterPill
        attribute='priorTreatmentsInclusion'
        attributeDisplay='Previous Treatment Type'
        filterValues={priorTreatmentsInclusion}
      />
      <FilterPill
        attribute='phaseList'
        attributeDisplay='Phase'
        filterValues={trialPhase}
      />
      <FilterPill
        attribute='interventionTypes'
        attributeDisplay='Treatment'
        filterValues={interventions}
      />
      <ClearFiltersPill />
    </div>
  )
}

const ClearFiltersPill = () => {
  const { clearAllSearchState } = useSearchUrlParameters()
  const allParams = getAllSearchParams()
  const viewingPartnerPage = useIsPartnerPage()

  const activeFilters = Object.keys(pickBy(allParams)).filter(
    (param) => !DEFAULT_FILTERS.includes(param),
  )

  if (activeFilters.length === 0) {
    return null
  }

  return (
    <div
      className={classMerge(
        'flex cursor-pointer items-center  p-1 px-3 font-semibold text-black',
        viewingPartnerPage ? 'text-black' : 'text-neutral600',
      )}
      onClick={() => {
        trackEvent(TrackingEvents.DIRECTORY_CLEAR_FILTERS_CLICKED, {
          type: 'Clear Filters Pill',
        })
        clearAllSearchState()
      }}
    >
      <Icon className='mr-2' icon={faRefresh} /> Clear Filters
    </div>
  )
}

const FilterPill = ({
  attribute,
  attributeDisplay,
  extraClearAttribute,
  filterValues,
}: {
  attribute: SearchParam
  attributeDisplay: string
  extraClearAttribute?: SearchParam
  filterValues: string | null
}) => {
  const { refine: clearGeneticRefinementState } = useClearRefinements({
    includedAttributes: extraClearAttribute
      ? [attribute, extraClearAttribute]
      : [attribute],
  })
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()

  if (!filterValues) {
    return <></>
  }

  const values = filterValues.split(',').filter((value) => value !== 'any')

  const manyOptions = values.length > 1

  if (values.length === 0) {
    return <></>
  }
  return (
    <div
      className={`inline-block cursor-pointer rounded-lg bg-white p-1 px-3`}
      onClick={() => {
        const attributesToClear = extraClearAttribute
          ? { [attribute]: undefined, [extraClearAttribute]: undefined }
          : { [attribute]: undefined }
        clearGeneticRefinementState()
        updateUrlToReflectSearchState(attributesToClear)
      }}
    >
      <Text className='text-neutral600' styleName='p-small'>
        {attributeDisplay}
        {manyOptions ? 's' : ''}:
        <span className='mr-2' />
        {values.join(', ')}
        <Icon className='ml-2' icon={faX} size='xs' />
      </Text>
    </div>
  )
}
