import { classMerge } from '@components/utilities/classMerge'

export const SkeletonLoader = () => {
  return (
    <div className='mt-12 flex animate-pulse justify-center'>
      <div className='hidden sm:mr-12 sm:block sm:size-[224px] sm:rounded-full sm:bg-neutral200'></div>
      <div className='w-full sm:w-[50%]'>
        <div className='flex flex-col space-y-4'>
          <div className='h-10 w-full rounded-xl bg-neutral200'></div>
          <div className='h-10 w-[90%] rounded-xl bg-neutral200'></div>
          <div className='h-10 w-[95%] rounded-xl bg-neutral200'></div>
        </div>
        <div className='mt-8 flex flex-col space-y-4'>
          <div className='h-6 w-[100%] rounded-xl bg-neutral200'></div>
          <div className='h-6 w-[40%] rounded-xl bg-neutral200'></div>
        </div>
        <div className='mt-8 flex justify-between'>
          <div className='h-12 w-[30%] rounded-xl bg-neutral200'></div>
          <div className='h-12 w-[30%] rounded-xl bg-neutral200'></div>
          <div className='h-12 w-[30%] rounded-xl bg-neutral200'></div>
        </div>
      </div>
    </div>
  )
}

export const ButtonLoader = ({ className }: { className?: string }) => {
  return (
    <div
      className={classMerge(
        'h-16 w-full animate-pulse rounded-full bg-neutral200',
        className,
      )}
    ></div>
  )
}
