import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { Icon } from '@components/primitives/Icon'
import { PaginationControlDisabledModifier } from '@components/primitives/PaginationControl/PaginationControlDisabledModifier'
import { Text } from '@components/primitives/Text'
import { faArrowSquareLeft } from '@fortawesome/pro-regular-svg-icons/faArrowSquareLeft'
import { trackEvent } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'

export const PreviousPageLink = ({ currentPage }: { currentPage: number }) => {
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()
  const onClick = () => {
    updateUrlToReflectSearchState({ page: (currentPage - 1).toString() })
    trackEvent(TrackingEvents.SEARCH_PAGINATION_PREVIOUS_CLICKED, {
      type: 'current-page-number',
      val: currentPage,
    })
  }

  const hasPreviousPage = currentPage > 1 // 1-based index, Algolia says first page is 1 not 0
  return hasPreviousPage ? (
    <div onClick={onClick}>
      <Text
        className='mx-2 my-4 size-11 cursor-pointer  bg-neutral100 p-2 text-center text-neutral900'
        value={<Icon icon={faArrowSquareLeft} />}
      />
    </div>
  ) : (
    /* Disabled button state: no previous page, at first page */
    <PaginationControlDisabledModifier direction='decrement' identifier={0} />
  )
}
