import Button from '@components/primitives/Button'
import Modal from '@components/primitives/Modal/index.client'
import { trackEvent } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import { useStats } from '@lib/utilities/algolia/useStats'
import type { ReactNode } from 'react'
import { useState } from 'react'

interface FilterModalContainerProps {
  buttonLabel: string
  children: ReactNode
  className?: string
  modalTitle: string
  onClearAll?: () => void
  trackingEventType?: string
}

const MAX_TRIALS_COUNT = 300

// View MAX+ Trials or View 123 Trials or View Trials
export const ViewTrialLabel = () => {
  const { nbHits } = useStats()

  const trialsCount = nbHits
  const label =
    trialsCount === 0
      ? `View Trials`
      : `View ${
          trialsCount > MAX_TRIALS_COUNT ? `${MAX_TRIALS_COUNT}+` : trialsCount
        } Trials`
  return <>{label}</>
}

const FilterModalFooter = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <div className='flex flex-row items-center justify-between border-t-2 border-t-neutral100 py-4'>
      <div className='pl-4'>
        {/* TODO: Make the following actually Clear All  */}
        {/* <Text
          className={'underline hover:cursor-pointer'}
          className={'text-dark'}
          onClick={onClearAll}
          styleName='p-small-strong'
          value={'Clear all'}
        /> */}
      </div>
      <div className='pr-4'>
        <Button
          className='rounded-full'
          color={'black'}
          onClick={closeModal}
          value={<ViewTrialLabel />}
        />
      </div>
    </div>
  )
}

/**
 * Provide a button and the corresponding modal when the button is clicked. The modal should contain
 * one or more attributes with facets to refine the current search context. This is a generic component
 * that should be called in more specific components that detail _what_ ought to be filtered, e.g. Patient
 * or Trial Details.
 *
 */
export default function FilterModal({
  buttonLabel,
  children,
  className,
  modalTitle,
  // onClearAll, // TODO: Implement this functionality: https://linear.app/withpower/issue/POW-1912
  trackingEventType,
}: FilterModalContainerProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const openModal = () => {
    setModalOpen(true)
    trackEvent(TrackingEvents.DIRECTORY_FILTER_PILL_CLICKED, {
      type: trackingEventType,
      val: 'open',
    })
  }
  const closeModal = (closeLabel: string) => () => {
    setModalOpen(false)
    trackEvent(TrackingEvents.DIRECTORY_FILTER_PILL_CLICKED, {
      type: trackingEventType,
      val: closeLabel,
    })
  }

  return (
    <div className={className}>
      <Button
        className='rounded-full'
        color={'neutral'}
        onClick={openModal}
        value={buttonLabel}
      />
      <Modal
        footer={
          <FilterModalFooter
            closeModal={closeModal('closeViaViewTrialsButton')}
          />
        }
        onClose={closeModal('closeViaX')}
        size={'Large'}
        title={modalTitle}
        visible={modalOpen}
      >
        {children}
      </Modal>
    </div>
  )
}
